import React from "react";
import "./NotFOUND.css"; // Import the CSS file

const Error404 = () => {
  return (
    <div>
      <div className="container text-center mb-5">
        <h1 className=" pt-2">Error 404!</h1>
        <div className="panel panel-default">
          <div className="panel-heading">
            <div className="fakeButtons fakeClose"></div>
            <div className="fakeButtons fakeMinimize"></div>
            <div className="fakeButtons fakeZoom"></div>
            <span className="user">devrolin@terminal:~</span>
          </div>
          <div className="panel-body ">
            <p className="line1" id="set-paracode">
            devrolin@terminal:~$error 404!<span className="cursor1">_</span>
            </p>
            <p className="line2" id="set-paracode">
            devrolin@terminal:~$Page Not Found.<span className="cursor2">_</span>
            </p>
            <p className="line3" id="set-paracode">
            devrolin@terminal:~$return back<span className="cursor3">_</span>
            </p>
            <p className="line4"><span className="cursor4">_</span></p>
          </div>
        </div>
      </div>
    
      
    </div>
  );
};

export default Error404;
